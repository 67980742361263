<template>
    <div>
        <ul>
<!--            <path-row v-for="(community, index) in results" :key="index" :community="community"/>-->
            <component :is="rowType" v-for="(community, index) in shownArray" :key="index" :community="community" :showUsername="showUsername"  />
        </ul>
        <MainPagination :maxInPage="maxInPage" @visibleResults="updateShownArray" :numberOfResults="results.length"/>

    </div>
</template>

<script>
    import _ from 'lodash'
    import MainPagination from "../views/MainPagination";
    import CommunityRow from "./CommunityRow";
    import CommunityTableRow from "./CommunityTableRow";
    export default {
        name: "PaginatedList",
        components: {
            CommunityTableRow,
            CommunityRow,
            MainPagination
        },
        props:{
            results: {
                default: []
            },
            listType: {
                default: 'community-row'
            },
            maxInPage: {
                default: 5
            }
        },
        created(){
            this.shownResults = {min: 1, max: this.maxInPage}
        },
        data(){
            return{
                shownResults: null,
                shownArray: [],
            }
        },
        watch: {
            maxInPage(newVal){
                console.log("It has changed!", newVal)
                this.shownResults = {min: 1, max: this.maxInPage}
                this.updateShownArray(this.shownResults)
            }
        },
        methods:{
            updateShownArray(shownResults){
                this.shownArray =  _.cloneDeep(this.results.slice(shownResults.min - 1, shownResults.max))
            }
        },
        computed:{
            showUsername() {
                return (this.listType !== 'profile' && this.listType !== 'dashboard')
            },
            rowType(){
                return (this.listType === 'profile' ? 'community-row' : 'community-row')
            },
        },
    }
</script>

<style scoped>

</style>
