<template>
        <li class="border-t border-gray-200">
            <router-link :to="`/${community.owner.username}/${community.name}`" tag="a" href="#" class="block hover:bg-gray-50 focus:outline-none focus:bg-gray-50 transition duration-150 ease-in-out">
                <div class="px-4 py-4 sm:px-6">
                    <div class="flex items-center justify-between">
                        <div class="text-sm leading-5 font-medium text-indigo-600 truncate">
                            {{ getNameWithSpace(community.name) }}
                        </div>
                        <div v-if="isCommunityEmpty" class="ml-2 flex-shrink-0 flex">
                            <span class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800">
                                Empty
                            </span>
                        </div>
                        <div v-else class="ml-2 flex-shrink-0 flex">
                            <span class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                                Active
                            </span>
                        </div>

                    </div>
                    <div class="mt-2 sm:flex sm:justify-between">
                        <div class="sm:flex">
                            <div class="mr-4 flex items-center text-sm leading-5 text-gray-500">
                                <svg class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" fill="currentColor" viewBox="0 0 20 20">
                                    <path d="M9 6a3 3 0 11-6 0 3 3 0 016 0zM17 6a3 3 0 11-6 0 3 3 0 016 0zM12.93 17c.046-.327.07-.66.07-1a6.97 6.97 0 00-1.5-4.33A5 5 0 0119 16v1h-6.07zM6 11a5 5 0 015 5v1H1v-1a5 5 0 015-5z"/>
                                </svg>
                                {{ community.owner.username }}
                            </div>
                            <div class="mr-6 mt-2 flex items-center text-sm leading-5 text-gray-500 sm:mt-0" >
                                <svg class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" fill="currentColor" viewBox="0 0 20 20">
                                    <path fill-rule="evenodd" d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z" clip-rule="evenodd"/>
                                </svg>
                                {{ privateLabel }}
                            </div>
                            <div v-if="community.stars_count > 0" class="mt-4 flex items-center text-sm leading-5 text-gray-500 sm:mt-0" >
                                <svg class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" fill="currentColor" viewBox="0 0 20 20">
                                    <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
<!--                                    <path fill-rule="evenodd" d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z" clip-rule="evenodd"/>-->
                                </svg>
                                {{ community.starsCount }}
                            </div>
                        </div>
                        <div class="mt-2 flex items-center text-sm leading-5 text-gray-500 sm:mt-0">
                            <svg class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" fill="currentColor" viewBox="0 0 20 20">
                                <path fill-rule="evenodd" d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z" clip-rule="evenodd"/>
                            </svg>
                            <span>
                Last Activity
                                <!--                <time datetime="2020-01-07">January 7, 2020</time>-->
                <time datetime="2020-01-07">{{ dateFromInput(community.lastUpdated) }}</time>
              </span>
                        </div>
                    </div>
                </div>
            </router-link>
        </li>



</template>

<script>
    import {dateFromInput} from "@/utils/helpers";
    export default {
        name: "CommunityTableRow",
        components: {

        },
        props: {
            community: {
                name: null,
                full_name: null,
                description: null,
                private: null,
                parent: null,
                stars_count: null,
                owner: {
                    username: null,
                },
                updated_at: null,
                created_at: null,
            }
        },
        data() {
            return {
            }
        },

        computed: {
            privateLabel() {
                return this.community.private? 'private' : 'Public'
            },
            lastUpdate() {
                const date = new Date(this.community.updated_at)
                return date.toLocaleString()
            },
            isCommunityEmpty(){
                return (this.community.size <= 20)
            },
        },
        methods:{
            getNameWithSpace(str){
                return str.replace(/_-/g, " ")
            },
            dateFromInput,
        }

    }
</script>

<style scoped>

</style>
