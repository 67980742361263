<template>
    <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">



        <div v-if="communities != null && communities.length === 0" class="my-10">
            <createCommunityCTA > </createCommunityCTA>
        </div>

        <div v-else-if="communities != null" class="bg-white rounded-md shadow-xl-white overflow-hidden sm:rounded-md my-10">

            <div class="bg-white px-4 py-5 border-b border-gray-200 sm:px-6">
                <div class="-ml-4 -mt-2 flex items-center justify-between flex-wrap sm:flex-no-wrap">
                    <div class="ml-4 mt-2">
                        <div class="flex items-center">
                            <div class="flex-shrink-0">
                                <img class="h-12 w-12 rounded-full" :src="getAvatarUrl" alt="" />
                            </div>
                            <div class="ml-4">
                                <h3 class="text-lg leading-6 font-medium text-gray-900">
                                    {{ getUsername }}
                                </h3>
                            </div>
                        </div>
                    </div>
                    <div class="bg-white px-4 py-5 sm:px-6">
                        <h1 class="text-3xl leading-6 font-bold text-gray-900">
                            My Communities
                        </h1>
                    </div>
                    <div class="ml-4 mt-2 flex-shrink-0">
                      <span class="inline-flex rounded-md shadow-sm">
                        <router-link tag="button" to="/newCommunity" type="button" class="relative inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:shadow-outline-indigo focus:border-indigo-700 active:bg-indigo-700">
                          Create new community
                        </router-link>
                      </span>
                    </div>
                </div>
            </div>

            <PaginatedList :results="communities"/>
        </div>

        <div v-if="starredCommunities != null" class="bg-white rounded-md shadow-xl-white overflow-hidden sm:rounded-md my-10">
            <div class="bg-white px-4 py-5 border-b border-gray-200 sm:px-6">
                <div class="-ml-4 -mt-2 flex items-center justify-center flex-wrap sm:flex-no-wrap">
                    <div class="bg-white px-4 py-5 sm:px-6">
                        <h1 class="text-3xl leading-6 font-bold text-gray-900">
                            My Stars
                        </h1>
                    </div>
                </div>
            </div>
            <PaginatedList listType="dashboard" :results="starredCommunities"/>
        </div>


        <div v-if="subscribedCommunities != null" class="bg-white rounded-md shadow-xl-white overflow-hidden sm:rounded-md my-10">
            <div class="bg-white px-4 py-5 border-b border-gray-200 sm:px-6">
                <div class="-ml-4 -mt-2 flex items-center justify-center flex-wrap sm:flex-no-wrap">
                    <div class="bg-white px-4 py-5 sm:px-6">
                        <h1 class="text-3xl leading-6 font-bold text-gray-900">
                            My Subscriptions
                        </h1>
                    </div>
                </div>
            </div>
            <PaginatedList :results="subscribedCommunities"/>
        </div>

        <div v-if="collaborations != null" class="bg-white rounded-md shadow-xl-white overflow-hidden sm:rounded-md my-10">
            <div class="bg-white px-4 py-5 border-b border-gray-200 sm:px-6">
                <div class="-ml-4 -mt-2 flex items-center justify-center flex-wrap sm:flex-no-wrap">
                    <div class="bg-white px-4 py-5 sm:px-6">
                        <h1 class="text-3xl leading-6 font-bold text-gray-900">
                            My Collaborations
                        </h1>
                    </div>
                </div>
            </div>
            <PaginatedList :results="collaborations"/>
        </div>

    </div>

</template>

<script>
    import CreateCommunityCTA from '../pages/communityPage/components/CreateCommunityCTA'
    import {mapGetters} from 'vuex'
    import * as axios from '../axios-auth'
    import PaginatedList from "../components/PaginatedList";
    export default {
        name: "Dashboard",
        components: {
            PaginatedList,
            // MainPagination,
            // communityRow: CommunityTableRow,
            CreateCommunityCTA: CreateCommunityCTA,
        },
        data() {
            return {
                communities: null,
                starredCommunities: null,
                subscribedCommunities: null,
                collaborations: null,
                community: {
                    name: null,
                    full_name: null,
                    description: null,
                    private: null,
                    parent: null,
                    owner: null
                }
            }
        },
        computed: {
            ...mapGetters(['getUsername', 'getToken', 'getAvatarUrl']),
        },
        created() {
            this.fetchCommunities()
        },
        methods: {
            fetchCommunities(){
                let loader = this.$loading.show({
                    // Optional parameters
                    container: this.fullPage ? null : this.$refs.formContainer,
                    canCancel: false,
                    loader: "dots",
                    color: "#45b3c2",
                    width: 128,
                    height: 128,
                    // onCancel: this.onCancel,
                });

                axios.jigo.get('/v2/user/communities')
                    .then((data) => {
                        this.communities = data.data
                        loader.hide()
                    })
                    .catch((err) => {
                        loader.hide()
                        console.error("Error occured fetching repos: ",err)
                    })

                axios.jigo.get('/v2/user/collaborations')
                    .then((data) => {
                        this.collaborations = data.data
                        loader.hide()
                    })
                    .catch((err) => {
                        loader.hide()
                        console.error("Error occured fetching repos: ",err)
                    })

                axios.jigo.get('/v2/user/stars')
                    .then((data) => {
                        this.starredCommunities = data.data
                        loader.hide()
                    })
                    .catch((err) => {
                        loader.hide()
                        console.error("Error occured fetching repos: ",err)
                    })

                axios.jigo.get('/v2/user/subscriptions')
                    .then((data) => {
                        this.subscribedCommunities = data.data
                        loader.hide()
                    })
                    .catch((err) => {
                        loader.hide()
                        console.error("Error occured fetching repos: ",err)
                    })

                // axios.jigit.get('/user/repos')
                //     .then((data) => {
                //         this.communities = data.data
                //         loader.hide()
                //     })
                //     .catch((err) => {
                //         loader.hide()
                //         console.log("Error occured fetching repos: ",err)
                //     })
                //
                // axios.jigit.get('/user/starred')
                //     .then((data) => {
                //         this.starredCommunities = data.data
                //         loader.hide()
                //     })
                //     .catch((err) => {
                //         loader.hide()
                //         console.log("Error occured fetching repos: ",err)
                //     })
                //
                // axios.jigit.get('/user/subscriptions')
                //     .then((data) => {
                //         this.subscribedCommunities = data.data
                //         loader.hide()
                //     })
                //     .catch((err) => {
                //         loader.hide()
                //         console.log("Error occured fetching repos: ",err)
                //     })
            },
            openCommunity(community){
                this.$router.push(`/${community.owner}/${community.name}`)
            },
            hideMe(){
                //DEBUG
            },
            getNameWithSpace(str){
                return str.replace(/_-/g, " ")
            },
        },

    }
</script>

<style scoped>

</style>
