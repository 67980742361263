<template>
    <div class="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6 select-none">
        <div class="flex-1 flex justify-between sm:hidden">
            <a href="#" class="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150">
                Previous
            </a>
            <a href="#" class="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150">
                Next
            </a>
        </div>
        <div class="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
            <div>
                <p class="text-sm leading-5 text-gray-700">
                    Showing
                    <span class="font-medium">{{ shownResults.min }}</span>
                    to
                    <span class="font-medium">{{shownResults.max}}</span>
                    of
                    <span class="font-medium">{{numberOfResults}}</span>
                    results
                </p>
            </div>
            <div>
                <nav class="relative z-0 inline-flex shadow-sm">
                    <div @click="previousPage" class="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm leading-5 font-medium text-gray-500 hover:text-gray-300 focus:z-10 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-100 active:text-gray-500 transition ease-in-out duration-150 cursor-pointer" aria-label="Previous">
                        <svg class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                            <path fill-rule="evenodd" d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clip-rule="evenodd" />
                        </svg>
                    </div>
                    <div v-for="page in numberOfPages" :key="page" @click="goToPage(page)"

                         class="-ml-px select-none relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm leading-5 font-medium text-gray-700 hover:text-gray-300 focus:z-10 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150 cursor-pointer"
                         :class="{'bg-purple-300': page === currentPage}">

                        {{page}}
                    </div>
<!--                    <a href="#" class="-ml-px relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm leading-5 font-medium text-gray-700 hover:text-gray-500 focus:z-10 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150">-->
<!--                        2-->
<!--                    </a>-->
<!--                    <a href="#" class="hidden md:inline-flex -ml-px relative items-center px-4 py-2 border border-gray-300 bg-white text-sm leading-5 font-medium text-gray-700 hover:text-gray-500 focus:z-10 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150">-->
<!--                        3-->
<!--                    </a>-->
<!--                    <span class="-ml-px relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm leading-5 font-medium text-gray-700">-->
<!--                        ...-->
<!--                    </span>-->
<!--                    <a href="#" class="hidden md:inline-flex -ml-px relative items-center px-4 py-2 border border-gray-300 bg-white text-sm leading-5 font-medium text-gray-700 hover:text-gray-500 focus:z-10 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150">-->
<!--                        8-->
<!--                    </a>-->
<!--                    <a href="#" class="-ml-px relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm leading-5 font-medium text-gray-700 hover:text-gray-500 focus:z-10 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150">-->
<!--                        9-->
<!--                    </a>-->
<!--                    <a href="#" class="-ml-px relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm leading-5 font-medium text-gray-700 hover:text-gray-500 focus:z-10 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150">-->
<!--                        10-->
<!--                    </a>-->
                    <a @click="nextPage" class="-ml-px relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm leading-5 font-medium text-gray-500 hover:text-gray-300 focus:z-10 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-100 active:text-gray-500 transition ease-in-out duration-150 cursor-pointer" aria-label="Next">
                        <svg class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                            <path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd" />
                        </svg>
                    </a>
                </nav>
            </div>
        </div>
    </div>

</template>

<script>
    export default {
        name: "MainPagination",
        props: {
            maxInPage: {
                default: 10
            },
            numberOfResults: {
            }
        },
        data(){
            return {
                currentPage: 1
            }
        },
        created(){
            this.$emit('visibleResults', this.shownResults)
        },
        methods: {
            nextPage(){
                if(this.currentPage >= this.numberOfPages){
                    return
                }
                this.currentPage = this.currentPage + 1
                this.$emit('visibleResults', this.shownResults)
            },
            previousPage(){
                if(this.currentPage <= 1){
                    return
                }
                this.currentPage = this.currentPage - 1
                this.$emit('visibleResults', this.shownResults)
            },
            goToPage(page){
                this.currentPage = page
                this.$emit('visibleResults', this.shownResults)
            }
        },
        computed: {
            numberOfPages(){
                let modulo = this.numberOfResults % this.maxInPage
                let additive = (modulo === 0 ) ? 0 : 1
                return (Math.floor(this.numberOfResults / this.maxInPage) + additive)
            },
            maxResultInCurrentPage(){
                return (this.numberOfResults >= (this.currentPage * this.maxInPage) ?
                    (this.currentPage * this.maxInPage) :
                    (this.numberOfResults))
            },
            minResultInCurrentPage(){
                return (this.maxInPage*(this.currentPage-1) + 1 )
            },
            shownResults(){
                return {max: this.maxResultInCurrentPage, min: this.minResultInCurrentPage}
            }
        },
        watch: {
            numberOfResults(){
                console.log("Emitting for the love of god!")
                this.$emit('visibleResults', this.shownResults)
            }
        }
    }
</script>

<style scoped>

</style>
